@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-primary bg-body text-white;
  }
  .h2 {
    @apply text-3xl capitalize;
  }
  .grad {
    @apply bg-gradient-to-t from-[#1F2126] to-[#33363D] drop-shadow-2xl;
  }
  .btn {
    @apply h-[50px] flex justify-center items-center rounded-[8px] px-10 py-[10px] text-sm uppercase font-bold;
  }
  .btn-accent {
    @apply bg-accent text-primary hover:bg-accent-hover transition-all;
  }
  .input {
    @apply h-[50px] rounded-[8px] w-full outline-none px-6 text-primary;
  }
  .order-input {
    @apply h-[50px] rounded-[8px] w-full outline-none px-6 text-primary bg-white;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(126 58 242);
  border-radius: 3px;
}

/* .custom-scrollbar::-webkit-scrollbar-track {
  background-color: #cbd5e0;
} */